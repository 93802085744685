import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_FORMALIZACION+'/v2');

export default {
  obtener_usuarios() {
    return http.get(`/usuarios`);
  }

  ,cuenta_obtener_empresa() {
    return http.get(`/usuarios/cuenta/empresa`);
  }

  ,cuenta_obtener_eventos() {
    return http.get(`/usuarios/cuenta/eventos`);
  }

  ,obtener_solicitudes() {
    return http.get(`/usuarios/solicitudes`);
  }

  ,obtener_solicitud(id) {
    return http.get(`/usuarios/solicitudes/${id}`);
  }
}