<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <div class="row">
          <h1 class="col-sm-12">Cita</h1>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12">
            <Calendar :options="{
              agregar_eventos: agregar_eventos, 
              agregar_invitados: false, 
              invitados: invitados,
              evento
            }" :eventos="eventos" :finalizar="finalizar_evento" @nEvento="crear_evento" @upEvent="actualizar_evento" @caEvent="cancelar_evento"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button v-if="solicitud.fecha_firma" class="btn btn-success" @click="$emit('avanzar')">Avanzar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Calendar from '@/components/Calendar'

  import apiUsuario from '@/apps/formalizacion/api/v2/usuarios'
  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  import appconfig from '@/apps/formalizacion/config'

  export default {
    components: {
      Calendar
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,solicitud: null
            ,nombre: null
            ,email: null
          }
        }
      }
    }
    ,data() {
      return {
        evento: {
          title: null
          ,descript: null
          ,location: null
        }
        ,invitados: []
        ,eventos: []
        ,agregar_eventos: true
        ,config: null
        ,finalizar_evento: false
      }
    }
    ,mounted() {
      this.config = appconfig[this.$helper.getEnv()];

      this.invitados = [
        {
          name: this.$auth.getUser().nombre
          ,email: this.obtener_email_notario()
        }
        ,{
          name: this.solicitud.nombre
          ,email: this.solicitud.email
        }
        ,...this.config.attendees
      ];

      this.evento.title = 'Solicitud '+this.solicitud.solicitud+': Firma de contrato'
      this.evento.descript = 'Evento para la firma de contrato entre el cliente y Bien para Bien ante notario'
      this.evento.location = this.solicitud.calle+', '+this.solicitud.colonia+', '+this.solicitud.municipio+', '+this.solicitud.estado+' '+this.solicitud.cp
      this.evento.id = this.solicitud.id;

      this.obtener_eventos();
    }
    ,methods: {
      async obtener_eventos() {
        try {
          this.eventos = (await apiUsuario.cuenta_obtener_eventos()).data;

          this.agregar_eventos = this.solicitud.fecha_firma == null;

          if(!this.agregar_eventos){
            let encontrado = false;
            for(let i=0; i<this.eventos.length; i++) {
              this.eventos[i].attendees = this.eventos[i].invitados;
              this.$log.info('solcitud_id: ',this.solicitud.id);
              this.$log.info('evento_solicitud_id: ',this.eventos[i].solicitud_id);
              if (this.eventos[i].solicitud_id === this.solicitud.id) {
                this.evento = this.eventos[i];
                this.$log.info('asignar evento cita', this.evento);
                encontrado = true;
                this.evento_agregado = true;
              }
            }

            this.$log.info('se encontro evento:', encontrado);
            this.$log.info('se encontro evento, data:', this.evento);
          }

          this.$log.info('eventos', this.eventos);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async crear_evento(evento) {
        this.$log.info('evento',evento);

        try {
          evento.event = 'firma_notario';
          let res = (await apiSolicitud.generar_cita(this.solicitud.id, evento));
          this.$log.info('res', res);

          this.finalizar_evento = true;
          this.agregar_eventos = false;
          this.obtener_eventos();
          this.$emit('avanzar');
        }catch(e) {
          // errores.push({evento: eventos[i].title, error: e.getMessage()});
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async actualizar_evento(evento) {
        try {
          let res = (await apiSolicitud.actualizar_cita(this.solicitud.id, evento));
          this.$log.info('res',res);

          this.agregar_eventos = false;
          this.obtener_eventos();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async cancelar_evento(idEvent) {
        try {
          let res = (await apiSolicitud.cancelar_cita(this.solicitud.id, idEvent));
          this.$log.info('res',res);

          this.agregar_eventos = false;
          this.obtener_eventos();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_email_notario() {
        this.$log.info('sesion: ',this.$auth.getUser());

        let email = null;

        this.$auth.getUser().contactos.forEach(contacto => {
          if (contacto.tipo == 'email' && email == null)
            email = contacto.valor;
        })

        return email;
      }
    }
  }
</script>