var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10 offset-sm-1"},[_vm._m(0),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-12"},[_c('Calendar',{attrs:{"options":{
            agregar_eventos: _vm.agregar_eventos, 
            agregar_invitados: false, 
            invitados: _vm.invitados,
            evento: _vm.evento
          },"eventos":_vm.eventos,"finalizar":_vm.finalizar_evento},on:{"nEvento":_vm.crear_evento,"upEvent":_vm.actualizar_evento,"caEvent":_vm.cancelar_evento}})],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-12 text-right"},[_c('button',{staticClass:"btn btn-warning mr-2",on:{"click":function($event){return _vm.$emit('retroceder')}}},[_vm._v("Retroceder etapa")]),(_vm.solicitud.fecha_firma)?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.$emit('avanzar')}}},[_vm._v("Avanzar")]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h1',{staticClass:"col-sm-12"},[_vm._v("Cita")])])
}]

export { render, staticRenderFns }